.certificates-container .control:first-of-type input {
    border-top-left-radius: var(--radius8);
    border-top-right-radius: var(--radius8);
    margin-bottom: 0px;
}

.certificte-title {
    margin-bottom: 4px;
}

.certificates-container .control:last-of-type input {
    border-bottom-left-radius: var(--radius8);
    border-bottom-right-radius: var(--radius8);
    border-bottom: 1px solid var(--color-grey40);
    margin-bottom: 8px;
}

.certificates-container .control:not(:last-of-type) input {
    border-bottom: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.certificates-container .control:not(:first-of-type) input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.certificates-container .control input:disabled {
    background-color: var(--color-white100);
    color: var(--color-grey100);
    border-top: 1px solid var(--color-grey40);
    border-right: 1px solid var(--color-grey40);
    border-left: 1px solid var(--color-grey40);
}

.certificates-container .control {
    display: flex;
    position: relative;
}

.certificates-container .error {
    display: flex;
    position: relative;
}

.certificates-container .error:first-child .help.is-danger {
    border-top-left-radius: var(--radius8);
    border-top-right-radius: var(--radius8);
}

.certificates-container .error:not(:first-child) .help.is-danger{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
}

.certificates-container .error:last-child .help.is-danger {
    border-bottom-left-radius: var(--radius8);
    border-bottom-right-radius: var(--radius8);
    margin-bottom: 8px;
}

.import-certs {
    height: 36px;
    background: var(--color-white100);
    border: 1px solid var(--color-grey40);
    border-radius: var(--radius8);
    font-size: var(--body-14);
}

.disabled {
    color: #757576;
    background: #dddddd;
    border-radius: var(--radius8);
}

.certificates-container .upload {
    display: flex;
    position: relative;
}

.certificates-container .upload:last-of-type input {
    border-bottom-left-radius: var(--radius8);
    border-bottom-right-radius: var(--radius8);
    border-bottom: 1px solid var(--color-grey40);
    margin-bottom: 8px;
}

.certificates-container .upload:not(:first-of-type) input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.upload-background {
    background-color: white;
    border-radius: var(--radius8);
    width: 100%;
    border: 1px solid var(--color-grey40);
    font-size: var(--body-14)
}

/* file upload button */
input[type="file"]::file-selector-button {
    height: 43px;
    width: 25%;
    cursor: pointer;
    background-color: var(--color-grey30);
    border: 1px var(--color-grey40);
    border-right: solid var(--color-grey40);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
    font-size: var(--body-14)
}
  
/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}
  
/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

.certificate-upload {
    margin-top: 4px;
    display: flex;
    position: relative;
}

.certificate-upload .error {
    margin-top: 0px;
    display: flex;
    position: relative;
    margin-bottom: 0px;
}