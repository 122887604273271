.multiple {
  padding-bottom: 10px;
}

.multiple .control {
  display: flex;
  margin-top: -1px;
  position: relative;
}

.multiple .control:hover {
  z-index: 1;
}

.multiple .control:first-child {
  margin: 0;
}

.multiple .control input {
  border-radius: 0;
}

.multiple .control:hover input {
  border: 1px solid var(--color-grey60);
}

.multiple .control:first-child input {
  border-radius: var(--radius8) var(--radius8) 0 0;
}

.multiple .control:last-child input {
  border-radius: 0 0 var(--radius8) var(--radius8);
}

.multiple .control:only-child input {
  border-radius: var(--radius8);
}

.remove-ntp-btn {
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.remove-ntp-btn img {
  display: block;
  filter: invert(86%) sepia(1%) saturate(29%) hue-rotate(353deg) brightness(98%) contrast(106%);
}

.multiple .control:hover .remove-ntp-btn img {
  filter: unset;  
}

.add-more-btn {
  border-radius: var(--radius8);
  background: var(--color-white100);
  border-color: var(--color-grey40);
  height: 36px;
  padding: 8px 12px;
  gap: 4px;
}

.add-more-btn img {
  width: 20px;
  height: 20px;
}
