.input-container.output {
  height: 300px;
}

.ace_editor {
  font-size: var(--body-14) !important;
  border-radius: var(--radius8);
}

.message {
  padding: 12px;
  background: var(--color-white100);
  border-radius: var(--radius8);
  border: 1px solid var(--color-grey30);
  display: flex;
  align-items: flex-start;
  gap: 4px;
  box-shadow: var(--input-drop-shadow);
}

.message img {
  width: 20px;
  height: 20px;
  margin: 2px 0;
}