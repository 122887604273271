.error-report.container {
  background-color: #1C1C1E;
  width: 100%;
  max-width: 100% !important;
  color: #FFFFFF;
}

.error-report > div {
  max-width: 990px;
  margin: 0 auto;
  width: 100%;
  padding: 4rem;
  min-height: 100vh;
}

.error-report .wordmark {
  max-width: 260px;
  margin-bottom: 3rem;
}

.error-report h1 {
  color: #FFFFFF;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.error-report form {
  color: #FFFFFF;
}

.error-report label {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.error-report .button {
  width: 100%;
  font-size: 1.2rem;
  margin: 2rem auto;
}

.error-report .form-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.error-report .form-row label {
  flex: 1;
  margin-right: 1rem;
  text-align: left;
}

.error-report .form-row input {
  flex: 2;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
}

.error-report .form-row > div {
  flex: 2;
}

.error-report .report {
  color: #ccc;
  background-color: #000000;
  padding: 2rem;
  border-radius: 1rem;
}
