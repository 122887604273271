.onboarding-container {
    background-color: #1C1C1E;
    background-image: linear-gradient(180deg, #1C1C1E, #0F0F0F);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.onboarding-container .wrapper {
    max-width: 800px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.onboarding-container .logo {
    width: 200px;
}

.onboarding-container .wordmark {
    height: 60px;
    margin-bottom: 80px;
}

.onboarding-container .hr {
    width: 50%;
    border-top: 1px solid var(--color-grey40);
    margin-top: 36px;
    margin-bottom: 42px;
}

.onboarding-container .onboarding-title {
    color: var(--color-white100);
    margin-bottom: 20px;
}

.onboarding-container .onboarding-desc {
    color: var(--color-grey60);
}

.onboarding-container .onboarding-desc a {
    color: var(--color-white100);
}

.onboarding-container .onboarding-desc img.learnmore {
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    border-bottom: var(--transparent);
}

.onboarding-container .onboarding-desc a:hover {
    border-bottom: 1px solid var(--color-white100);
}

.onboarding-container .onboarding-note {
    width: 100%;
    padding: 40px;
    background: var(--color-black100);
    border: 1px solid var(--color-grey100);
    border-radius: 25px;
    text-align: center;
}

.onboarding-container .complete-onboarding {
    margin-top: 60px;
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width: 600px) {
    .onboarding-container .wrapper {
        max-width: 100%;
    }
}