.proxy-container {
    display: flex;
}

.proxy-container .ip-address {
    margin-right: 12px; 
    flex: 4;
}

.proxy-container .port {
    flex: 1;
}